import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Dashboard from "../Dashboard";
import {
  Button,
  Flex,
  message,
  Modal,
  Form,
  Upload,
  AutoComplete,
  Spin,
} from "antd";
import {
  FormOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  db,
  doc,
  query,
  getDocs,
  collection,
  deleteDoc,
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  setDoc,
  updateDoc,
  where,
} from "../../firebase";
import Title from "antd/es/typography/Title";
import CreateWipForm from "../../Component/CreateWipForm";
import {  useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";

const collectionName = "batches";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const MasterclassManagement = () => {
  const [batches, setBatches] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [random, setRandom] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState([]);
  const [acticeBatch, setActiveBatch] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [wipMenu, setWipMenu] = useState({
    create: false,
    view: false,
    edit: false,
  });

  const [formData, setFormData] = useState({
    name: "",
    logo_url: "",
    details: "",
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
    logo_url: "",
    id: "",
    details: "",
  });
  const [fileList, setFileList] = useState([
    // {
    //   uid: "-1",
    //   name: "image.png",
    //   status: "done",
    //   url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    // },
  ]);

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Action Completed Successfully",
    });
  };


  // Form Submit
  const handleOk = async () => {
    // setIsModalOpen(false);
    // console.log(formData);
    // If not create form
    // TODO: user drawer for form
    if (!isEdit) {
      try {
        console.log(fileList[0]);
        // return;
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `/masterclass-images/${fileList[0]?.name}`
        );

        let imageURL = "";


        uploadBytes(storageRef, fileList[0]?.originFileObj, { contentType: "image/png" }).then(
          (snapshot) => {
            message.success(`${fileList[0].name} file uploaded successfully.`);
            getDownloadURL(snapshot.ref).then(async (downloadURL) => {
              console.log(downloadURL);
              const updatedMaster = await setDoc(doc(collection(db, "masterclasses")), {
                name: formData?.name,
                image: downloadURL,
                details: formData?.details,
                batchId: selectedBatch?.id,
              });

              imageURL = downloadURL;
              message.success("Batch Created Successfully");
              setIsModalOpen(false);
              setFormData({
                name: "",
                image: "",
                details: ""
              });

              handleSearchBatch();
            });
          }
        );
      } catch (error) {
        console.log(error);
        message.error("There was some error creating batch");
      }

      setFileList([]);
      setIsEdit(false);
      return;
    }

    // If edit form
    try {
      console.log(editFormData);
      const masterClassRef = doc(db, "masterclasses", editFormData?.documentId);

      await updateDoc(masterClassRef, {
        name: editFormData?.name,
        details: editFormData?.details,
        image: editFormData?.image,
      });

      message.success("Batch Created Successfully");
      setIsModalOpen(false);
      setEditFormData({
        name: "",
        image: "",
        details: "",
      });

      handleSearchBatch();

    } catch (error) {
      console.log(error);
      message.error("There was some error creating batch");
    }

    setIsEdit(false);
    return;
  };

  useEffect(() => {
    getBatches();
  }, [random, isEdit]);

  const getBatches = async () => {
    const querySnapshot = await getDocs(collection(db, collectionName));
    const batchData = [];
    querySnapshot.forEach((doc) => {
      batchData.push({
        key: doc.id,
        id: doc.id,
        value: doc.data()?.name,
        ...doc.data(),
      });
    });

    setBatches(batchData);
  };

  // Delete on entry
  const handleDelete = async (e, param) => {
    try {
      await deleteDoc(doc(db, "masterclasses", param?.row?.documentId));

      // Remove the deleted item from the state
      const batchCopy = batches;

      handleSearchBatch();

      // setBatches(batchCopy);
      // setRandom(Math.random);
      success();
    } catch (error) {
      console.log(error);
      error();
    }

    setIsEdit(false);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "image",
      headerName: "Image",
      width: 150,
      editable: true,
      renderCell: (param) => (
        <img  src={param?.row?.image} height={30}/>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 400,
      editable: true,
    },
    {
      field: "details",
      headerName: "Descriptor",
      width: 220,
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 160,

      renderCell: (param) => (
        <>
          <FormOutlined  onClick={() => {
            console.log(param?.row)
            setFileList((prevValue) => {
              return [{
              uid: "-1",
              name: "image.png",
              status: "done",
              url: param?.row?.image
            }]})

            setEditFormData({
              name: param?.row?.name,
              image: param?.row?.image,
              details: param?.row?.details,
              id: param?.row?.id,
              batchId: param?.row?.batchId,
              documentId: param?.row?.documentId
            });

            setIsEdit(true);
            setIsModalOpen(true);
          }} 
            style={{ cursor: "pointer", margin: "0 7px" }} 
          />
          <DeleteOutlined onClick={(e) => handleDelete(e, param)} style={{ cursor: "pointer", margin: "0 7px" }} />
        </>
      ),
    },
  ];

  const handleSearchBatch = async () => {
    // selectedBatch
    setIsLoading(true);
    const masterclassRef = collection(db, "masterclasses");

    console.log(selectedBatch?.id);

    const q = query(masterclassRef, where("batchId", "==", selectedBatch?.id));
    const querySnapshot = await getDocs(q);
    const masterClassData = [];

    let index = 1;

    querySnapshot.forEach((doc) => {
      masterClassData.push({
        image: doc.data()?.image,
        batchId: doc.data()?.batchId,
        name: doc.data()?.name,
        details: doc.data()?.details,
        url: doc.data()?.image,
        documentId: doc?.id,
        id: index,
      });

      index++;
    });

    console.log(masterClassData);
    setActiveBatch(masterClassData);
    setIsLoading(false);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ file }) => {
    console.log(file);
    setFileList([file]);
  };

  return (
    <Dashboard>
      <>
        {contextHolder}
        <Modal
          title={isEdit ? "Edit Masterclass" : "Create Masterclass"}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={() => {
            setFormData({
              name: "",
              image: "",
              details: ""
            });

            setFileList([]);
            setIsEdit(false);
            setIsModalOpen(false);
          }}
        >
          <Upload
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          <TextArea
            value={isEdit ? editFormData?.name : formData?.name}
            onChange={(e) =>
              isEdit ? 
              setEditFormData((prevValue) => {
                return {
                  ...prevValue,
                  name: e.target.value,
                };
              })
              :
              setFormData((prevValue) => {
                return {
                  ...prevValue,
                  name: e.target.value,
                };
              })
            }
            placeholder="Name of the masterclass"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />

          {/* Detail */}
          <TextArea
            value={isEdit ? editFormData?.details : formData?.details}
            onChange={(e) =>
              isEdit ? 
              setEditFormData((prevValue) => {
                return {
                  ...prevValue,
                  details: e.target.value,
                };
              })
              :
              setFormData((prevValue) => {
                return {
                  ...prevValue,
                  details: e.target.value,
                };
              })
            }
            placeholder="Detail"
            autoSize={{ minRows: 3, maxRows: 5 }}
            style={{
              marginTop: 10
            }}
          />
        </Modal>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
        <Flex
          style={{ width: "100%", marginBottom: 10, marginTop: -10 }}
          justify="space-between"
        >
          <Title level={5}>Masterclass Management</Title>
          {!wipMenu.create && (
            <div>
              <AutoComplete
                style={{
                  width: 200,
                  marginRight: "10px",
                }}
                onChange={(e, data) =>
                  setSelectedBatch({
                    name: data?.name,
                    id: data?.id,
                  })
                }
                options={batches}
                placeholder="Start Typing"
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />

              <Button
                onClick={() => {
                  handleSearchBatch();
                }}
              >
                Search
              </Button>
            </div>
          )}
        </Flex>

        <>
          {isLoading ? (
            <Spin
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90%",
              }}
              size="large"
            ></Spin>
          ) : (
            <>
              {acticeBatch.length !== 0 ? (
                <>
                  <Button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "7px",
                      marginTop: "20px",
                    }}
                    onClick={() => {
                      console.log("CLick");
                      setIsModalOpen(true);
                    }}
                  >
                    <PlusOutlined />
                  </Button>

                  <DataGrid
                    rows={acticeBatch}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 25,
                        },
                      },
                    }}
                    density="compact"
                    // pageSizeOptions={[5]}
                    pageSizeOptions={[5, 10, 25]}
                    disableRowSelectionOnClick
                    sx={{ height: "85%" }}
                  />
                </>
              ) : (
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "7px",
                    marginTop: "20px",
                  }}
                  onClick={() => {
                    console.log("CLick");
                    console.log(selectedBatch);
                    if (selectedBatch.length === 0) {
                      message.error("Please select a batch");
                      return;
                    }
                    setIsModalOpen(true);
                  }}
                >
                  <PlusOutlined />
                </Button>
              )}
            </>
          )}
        </>

        {wipMenu.create && <CreateWipForm />}

      </>
    </Dashboard>
  );
};

export default MasterclassManagement;
