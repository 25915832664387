import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  Flex,
  Space,
  Table,
  message,
  Modal,
  Form,
  Input,
  Upload,
  Select,
  Card,
  Divider,
  Spin,
} from "antd";
import {
  FormOutlined,
  DeleteOutlined,
  InboxOutlined,
  CopyOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  db,
  doc,
  query,
  getDocs,
  collection,
  deleteDoc,
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  setDoc,
  updateDoc,
} from "../../firebase";
import Title from "antd/es/typography/Title";
import TextArea from "antd/es/input/TextArea";
import Loader from "../../Component/Loader";

const emailCollectionName = "emails";
const batchCollectionName = "batches";

const EmailManagement = () => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [batches, setBatches] = useState(null);
  const [emailList, setEmailList] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [random, setRandom] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [formData, setFormData] = useState({
    subject: null,
    body: "",
    batchName: "",
    batchId: "",
    batchLogo: "",
  });
  const [editFormData, setEditFormData] = useState({
    id: null,
    subject: null,
    body: "",
    batchName: "",
    batchId: "",
    batchLogo: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const showModal = () => {
    setIsModalOpen(true);
  };

  // Form Submit
  const handleOk = async () => {
    // setIsModalOpen(false);
    // console.log(formData);
    // If not create form
    if (!isEdit) {
      try {
        await setDoc(doc(collection(db, emailCollectionName)), {
          subject: formData?.subject,
          body: formData?.body,
          batch_name: formData?.batchName,
          batch_id: formData?.batchId,
          logo_url: formData?.batchLogo,
        });
        message.success("Batch Created Successfully");
        setIsModalOpen(false);
        setFormData({
          subject: null,
          body: "",
          batchName: "",
          batchId: "",
          batchLogo: "",
        });
        setRandom(Math.random);
      } catch (error) {
        console.log(error);
        message.error("There was some error creating batch");
      }

      setIsEdit(false);
      return;
    }

    // If edit form
    try {

      console.log("Edit")
      console.log(editFormData)

      const editEmailForm = doc(db, emailCollectionName, editFormData?.id);

      // Set the "capital" field of the city 'DC'
      await updateDoc(editEmailForm, {
        subject: editFormData?.subject,
        body: editFormData?.body,
        batch_name: editFormData?.batchName,
        batch_id: editFormData?.batchId,
        logo_url: editFormData?.batchLogo,
      });

      

      message.success("Batch Created Successfully");
      setIsModalOpen(false);
      setEditFormData({
        id: null,
        subject: null,
        body: "",
        batchName: "",
        batchId: "",
        batchLogo: "",
      });
      setRandom(Math.random);
      return ;
    } catch (error) {
      console.log(error);
      message.error("There was some error creating batch");
      return;
    }

    setIsEdit(false);
    return;
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  useEffect(() => {
    getData();
  }, [random, isEdit]);

  const getData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, batchCollectionName));
      const batchData = [];
      querySnapshot.forEach((doc) => {
        batchData.push({
          key: doc.id,
          id: doc.id,
          ...doc.data(),
          value: doc?.id,
          label: doc.data()?.name,
        });
      });

      const emailData = await getDocs(collection(db, emailCollectionName));
      const emailDataArray = [];
      emailData.forEach((doc) => {
        emailDataArray.push({
          key: doc.id,
          emailId: doc.id,
          ...doc.data(),
        });
      });

      setEmailList(emailDataArray);
      setBatches(batchData);
    } catch (error) {
      message.error("Error retrieving batch data");
    }
  };

  // Delete on entry
  const handleDelete = async (record) => {
    try {
      console.log(record);
      await deleteDoc(doc(db, emailCollectionName, record?.key));

      // Remove the deleted item from the state
      const batchCopy = batches;

      batches.map((batch, index) => {
        if (batch.id === record.id) {
          batchCopy.splice(index, 1);
        }
      });

      setBatches(batchCopy);
      setRandom(Math.random);
      message.success("Successfully Deleted Email");
    } catch (error) {
      console.log(error);
      message.error("There was some error deleting email");
    }

    setIsEdit(false);
  };

  // Edit one entry
  const handleEdit = (record) => {
    console.log(record.emailId)
    setEditFormData({
      id: record?.emailId,
      subject: record?.subject,
      body: record?.body,
      batchName: record?.batch_name,
      batchId: record?.batch_id,
      batchLogo: record?.logo_url,
    });
    setIsEdit(true);
    showModal();
  };

  const handleView = (record) => {
    console.log(record);
    setSelectedEmail(record);
    setIsViewModalOpen(true);
  };

  const onFinish = (data) => {
    console.log(data);
  };

  const handleInputChange = (e) => {
    setFormData((prevValue) => {
      return {
        ...prevValue,
        name: e.target.value,
      };
    });
  };

  const columns = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "suject",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === "subject" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Batch Name",
      dataIndex: "batch_name",
      key: "batch_name",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        sortedInfo.columnKey === "batch_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Action",
      width: 150,
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => handleView(record)}>
            <EyeOutlined />
          </a>
          <a onClick={() => handleEdit(record)}>
            <FormOutlined />
          </a>
          <a onClick={() => handleDelete(record)}>
            <DeleteOutlined />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <Dashboard>
      <>
        {contextHolder}
        {/* View Modal */}
        <Modal
          title={"View Email"}
          open={isViewModalOpen}
          onCancel={() => {
            setSelectedEmail(null);
            setIsViewModalOpen(false);
          }}
          onOk={() => {
            setSelectedEmail(null);
            setIsViewModalOpen(false);
          }}
          cancelButtonProps={{ hidden: "none" }}
        >
          <p style={{ marginTop: 20, marginBottom: 2 }}>Subject:</p>
          <Input placeholder={selectedEmail?.subject} disabled />
          <p style={{ marginTop: 10, marginBottom: 2 }}>Body:</p>
          <div
            style={{
              padding: 10,
              paddingTop: 20,
              color: "rgba(0, 0, 0, 0.25)",
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              borderWidth: "1px",
              borderStyle: "solid",
              borderRadius: "6px",
              borderColor: "#d9d9d9",
              boxShadow: "none",
              cursor: "not-allowed",
              opacity: "1",
            }}
          >
            <div
              style={{ lineHeight: 0 }}
              dangerouslySetInnerHTML={{
                __html: selectedEmail?.body,
              }}
            />
          </div>

          <p style={{ marginTop: 10, marginBottom: 2 }}>Batch Name:</p>
          <Input placeholder={selectedEmail?.batch_name} disabled />
        </Modal>

        {/* Create Modal */}
        <Modal
          title={isEdit ? "Edit Email" : "Create Email"}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {isEdit ? (
            <>
              <Input
                placeholder="Subject"
                style={{ marginTop: 20 }}
                onChange={(e) =>
                  setEditFormData((prevValue) => {
                    return {
                      ...prevValue,
                      subject: e.target.value,
                    };
                  })
                }
                value={editFormData.subject}
              />
              <ReactQuill
                theme="snow"
                value={editFormData?.body}
                onChange={(e) =>
                  setEditFormData((prevValue) => {
                    return {
                      ...prevValue,
                      body: e,
                    };
                  })
                }
                style={{ marginTop: 10, height: 200 }}
              />
              {console.log(batches)}
              <Select
                showSearch
                style={{
                  width: 200,
                  marginTop: 55,
                }}
                placeholder="Select A Batch"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                value={editFormData?.batchName}
                onSelect={(e, value) =>
                  setEditFormData((prevValue) => {
                    return {
                      ...prevValue,
                      batchName: value?.name,
                      batchId: value?.id,
                      batchLogo: value?.logo_url,
                    };
                  })
                }
                options={batches}
              />

              <Card
                type="inner"
                title="Instructions"
                style={{ marginTop: 10, opacity: 0.7 }}
              >
                <p>
                  <i>
                    Note: Make sure you dont use the below tag's in the subject
                  </i>
                </p>
                <p>
                  [name] This will autofill user's first name. Use only in body
                </p>
                <p>[wiplink] This will auto fill wip link. Use only in body</p>
              </Card>
            </>
          ) : (
            <>
              <Input
                placeholder="Subject"
                style={{ marginTop: 20 }}
                onChange={(e) =>
                  setFormData((prevValue) => {
                    return {
                      ...prevValue,
                      subject: e.target.value,
                    };
                  })
                }
                value={formData.name}
              />
              <ReactQuill
                theme="snow"
                value={formData?.body}
                onChange={(e) =>
                  setFormData((prevValue) => {
                    return {
                      ...prevValue,
                      body: e,
                    };
                  })
                }
                style={{ marginTop: 10, height: 200 }}
              />
              {console.log(batches)}
              <Select
                showSearch
                style={{
                  width: 200,
                  marginTop: 55,
                }}
                placeholder="Select A Batch"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                onSelect={(e, value) =>
                  setFormData((prevValue) => {
                    return {
                      ...prevValue,
                      batchName: value?.name,
                      batchId: value?.id,
                      batchLogo: value?.logo_url,
                    };
                  })
                }
                options={batches}
              />

              <Card
                type="inner"
                title="Instructions"
                style={{ marginTop: 10, opacity: 0.7 }}
              >
                <p>
                  <i>
                    Note: Make sure you dont use the below tag's in the subject
                  </i>
                </p>
                <p>
                  [name] This will autofill user's first name. Use only in body
                </p>
                <p>[wiplink] This will auto fill wip link. Use only in body</p>
              </Card>
            </>
          )}
        </Modal>
        <Flex
          style={{ width: "100%", marginBottom: 10, marginTop: -10 }}
          justify="space-between"
        >
          <Title level={5}>Email Management</Title>
          <Button onClick={showModal}>Create</Button>
        </Flex>
        {console.log(formData?.subject)}
        {emailList ? (
          <Table
            columns={columns}
            dataSource={emailList}
            size="small"
            onChange={handleChange}
          />
        ) : (
          <Loader />
        )}
      </>
    </Dashboard>
  );
};

export default EmailManagement;
