import {
    collection,
    db,
    getDoc,
    getDocs,
    query,
    where
} from "../firebase"

const FUNCTION_URL = "https://us-central1-wip-host.cloudfunctions.net/app/email";
// const FUNCTION_URL = "http://127.0.0.1:5001/wip-host/us-central1/app/email";
const wipLink = "https://wip.workverse.in/";

export const sendEmail = async (batchId, userList) => {

    // Get the batch's Email Content
    const q = query(collection(db, "emails"), where("batch_id", "==", batchId));

    // TODO: Allow only 1 email for 1 batch
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length === 0) throw Error("Email not found for the selected batch")

    let email = [];

    try {
        querySnapshot.forEach((doc) => {
            email.push({
                ...doc.data(),
                id: doc.id
            })
        })
        const subject = email[0].subject;
        const body = email[0].body;

        let emailList = [] //Array of objects with email content and list

        userList.forEach((user) => {

            console.log(user);

            let modifiedBody = body.replace("[name]", user.name);
            modifiedBody = modifiedBody.replace("[wiplink]", wipLink + user.uniqueLink);

            emailList.push({
                to: user.emailId,
                subject: subject,
                body: modifiedBody
            })


        })

        console.log(emailList);

        const response = await fetch(FUNCTION_URL, {
            mode: 'no-cors',
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                emailData: emailList
            })
        });

        return response;

    } catch (error) {
        throw Error(error);
    }







}
