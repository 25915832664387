import { ThunderboltOutlined } from "@ant-design/icons";
import { Input, Select, Col, Row, Button, Divider, Spin, message,Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { collection, db, doc, getDoc, getDocs, setDoc } from "../firebase";
import { useNavigate } from "react-router-dom";
import Dashboard from "../Page/Dashboard";

const style = { padding: "6px" };



const CreateWipForm = () => {

  const [loading, setLoading] = useState(false);
  const [uniqueNumber, setUniqueNumber] = useState(0);
  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [formData, setFormData] = useState({
    wipID: "WV" + Math.floor(100000 + Math.random() * 999999),
    fullName: "",
    emailId: "",
    wipValidity: "",
    stream: "",
    educationalInstitute: null,
    hoursOfRolePlay: null,
    problemsSolved: null,
    uniquePersonalities: null,
    workplaceTools: null,
    problemSolving: null,
    smartThinking: null,
    remoteCommunication: null,
    negotiation: null,
    emotionalIntelligence: null,
    storytelling: null,
    collaboration: null,
    productivity: null,
    uniqueLink: null,
    framesLogic: false,
    connectsRelated: false,
    communicates: false,
    makesInformed: false,
    breakDownProblem: false,
  })

  const navigate = useNavigate();

  useEffect(() => {
    handleGenerateUniqueLink();
    getBatches();
  },[])


  const handleGenerateUniqueLink = () => {
    const randomNumber = Math.floor(100000 + Math.random() * 999999);
    setUniqueNumber(randomNumber);
  }

  const getBatches = async() => {
    const querySnapshot = await getDocs(collection(db, "batches"));
    const batchData = [];
    querySnapshot.forEach((doc) => {
      batchData.push({
        key: doc.id,
        id: doc.id,
        ...doc.data(),
        value: doc?.id,
        label: doc.data()?.name,
      });
    });
    setBatches(batchData);
  }

  

  const handleSave = async () => {
    setLoading(true)
    if(!formData.emailId) {
      message.error("Please Enter Email ID");
      setLoading(false)
      return;
    }

    if(!selectedBatch){
      message.error("Please Select A Batch")
      setLoading(false)
      return;
    }

    try {
      const result = await setDoc(doc(collection(db, "wips")), {
        uniqueLink: formData.uniqueLink,
        //TODO: WIP ID
        wipId: formData.wipID,
        name: formData.fullName,
        emailId: formData.emailId,
        wipValidity: formData.wipValidity,
        // TODO: GET WIP VALIDIDY
        stream: formData.stream,
        educationalInstitute: formData.educationalInstitute,
        hoursOfRolePlay: formData.hoursOfRolePlay,
        problemsSolved: formData.problemsSolved,
        uniquePersonalities: formData.uniquePersonalities,
        // For Bars
        workplaceTools: formData.workplaceTools,
        problemSolving: formData.problemSolving,
        smartThinking: formData.smartThinking,
        remoteCommunication: formData.remoteCommunication,
        negotiation: formData.negotiation,
        emotionalIntelligence: formData.emotionalIntelligence,
        storytelling: formData.storytelling,
        collaboration: formData.collaboration,
        productivity: formData.productivity,
        // Interpretation
        framesLogic: formData.framesLogic,
        connectsRelated: formData.connectsRelated,
        communicates: formData.communicates,
        makesInformed: formData.makesInformed,
        breakDownProblem: formData.breakDownProblem,
        batchId: selectedBatch,
        emailSent: "Pending",
      })        
        console.log("success")
        navigate("/admin/wip");
        message.success("Successfully Generated WIP");
        setLoading(false)
      
    } catch (error) {
      message.error(error)
      
    }
    console.log("Here")
    navigate("/admin/wip");
    console.log("Then")

  }

  const handleCheckBox = (e) => {
    console.log(e.target);
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [e.target.value]: e.target.checked,
      }
    })

    console.log(formData);
  }

  const handleChange = (e) => {
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      }
    })
    console.log(formData);
  } 

  return (
    <Dashboard >
    <div>
      <Row gutter={16}>
        <h6 style={{marginBottom: "-20px", marginLeft: "8px", marginTop: "10px"}}>Basic Details</h6>
        <Divider />
        <Col className="gutter-row" style={style} span={12}>
          <Input placeholder="Unique Link" value={formData.uniqueLink} disabled/>
        </Col>
        <Col className="gutter-row" style={style} span={12}>
          <Input value={formData.wipID} placeholder="WIP Id" disabled/>
        </Col>
        <Col className="gutter-row" style={style} span={12}>
        <Input onChange={handleChange} name="emailId" value={formData.emailId} placeholder="Email Id" type="email" required />
        </Col>
        <Col className="gutter-row" style={style} span={12}>
          <Input  placeholder="Full Name" name="fullName" value={formData.fullName} onChange={(e) => {
            setFormData((prevVale) => {
              return {
                ...prevVale,
                fullName: e.target.value,
                uniqueLink: (e.target.value + uniqueNumber).replace(/ +/g, "").toLowerCase()
              }
            })

          }} />
        </Col>
        <Col className="gutter-row" style={style} span={12}>
          <Input onChange={handleChange} name="wipValidity"  value={formData.wipValidity} placeholder="WIP Validity : dd/mm/yyyy" />
        </Col>
        <Col className="gutter-row" style={style} span={12}>
          <Input  onChange={handleChange} name="stream" value={formData.stream} placeholder="Stream" />
        </Col>
        <Col className="gutter-row" style={style} span={12}>
          <Input onChange={handleChange} name="educationalInstitute" value={formData.educationalInstitute} placeholder="Educational Institutions" />
        </Col>
        <Col className="gutter-row" style={style} span={12}>
         
        </Col>

        <h6 style={{marginBottom: "-20px", marginLeft: "8px", marginTop: "20px"}}>Training Stats</h6>
        <Divider />

        <Col className="gutter-row" style={style} span={6}>
          <Input onChange={handleChange} name="hoursOfRolePlay" value={formData.hoursOfRolePlay} placeholder="Hours of roleplay undertaken" type="number" />
        </Col>
        <Col className="gutter-row" style={style} span={6}>
          <Input onChange={handleChange} name="problemsSolved" value={formData.problemsSolved} placeholder="Problems Solved" type="number" />
        </Col>
        <Col className="gutter-row" style={style} span={6}>
          <Input onChange={handleChange} name="uniquePersonalities" value={formData.uniquePersonalities} placeholder="Unique Personalities Tackle" type="number" />
        </Col>
        <Col className="gutter-row" style={style} span={6}>
          <Input  onChange={handleChange} name="workplaceTools" value={formData.workplaceTools} placeholder="Workplace Tool Learnt" type="number" />
        </Col>

        <h6 style={{marginBottom: "-20px", marginLeft: "8px", marginTop: "20px"}}>Soft Skills Proficiency</h6>
        <Divider />

        <Col className="gutter-row" style={style} span={6}>
          <Input  onChange={handleChange} name="problemSolving" value={formData.problemSolving} placeholder="Problem Solving" type="number" />
        </Col>
        <Col className="gutter-row" style={style} span={6}>
          <Input onChange={handleChange} name="smartThinking" value={formData.smartThinking} placeholder="Smart Thinking" type="number" />
        </Col>
        <Col className="gutter-row" style={style} span={6}>
          <Input onChange={handleChange} name="remoteCommunication" value={formData.remoteCommunication} placeholder="Remote Communication" type="number" />
        </Col>
        <Col className="gutter-row" style={style} span={6}>
          <Input onChange={handleChange} name="negotiation" value={formData.negotiation} placeholder="Negotiation" type="number" />
        </Col>


        <Col className="gutter-row" style={style} span={6}>
          <Input onChange={handleChange} name="emotionalIntelligence" value={formData.emotionalIntelligence} placeholder="Emotional Intelligence" type="number" />
        </Col>
        <Col className="gutter-row" style={style} span={6}>
          <Input onChange={handleChange} name="storytelling" value={formData.storytelling} placeholder="Storytelling" type="number" />
        </Col>
        <Col className="gutter-row" style={style} span={6}>
          <Input onChange={handleChange} name="collaboration" value={formData.collaboration} placeholder="Collaboration" type="number" />
        </Col>
        <Col className="gutter-row" style={style} span={6}>
          <Input onChange={handleChange} value={formData.productivity} name="productivity" placeholder="Productivity" type="number" />
        </Col>

        <Checkbox.Group
          style={{
            width: '100%',
            margin: "20px 0 20px 7px"
          }}
        >
          <Row>
            <Col span={24}>
              <Checkbox onChange={handleCheckBox} value="framesLogic">Frames questions with logic and foresight</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox onChange={handleCheckBox} value="connectsRelated">Connects the dots to draw actionable conclusions</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox  onChange={handleCheckBox} value="communicates">Communicates with clarity, context and creativity</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox  onChange={handleCheckBox} value="makesInformed">Takes independent decisions and makes informed assumptions</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox onChange={handleCheckBox} value="breakDownProblem">Breaks down problems to their fundamental elements</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>

         <Col className="gutter-row" style={style} span={12}>
          <Select
            showSearch
            style={{
              width: 200,
            }}
            placeholder="Select A Batch"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            onSelect={
              (e, value) => {
                console.log(e)
                setSelectedBatch(e);
              }}
            options={batches}
          />
        </Col>



        <Col className="gutter-row" style={style} span={24}>
          {/* TODO: Add Loader */}
          <Button style={{width: "150px", marginTop: "10px"}} onClick={() => handleSave()}>
            {loading ? <Spin /> : "Save"}
          </Button>
        </Col>
        <p style={{fontStyle: "italic", opacity: "0.7"}}>Note: Masterclass Data will be taken from the batch</p>

      </Row>
    </div>
    </Dashboard>
  );
};

export default CreateWipForm;
