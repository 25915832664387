import React, { useContext, useEffect, useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Card, Flex, Spin, message } from "antd";
import { auth, signInWithEmailAndPassword } from "../firebase";
import UserContext from "../Context/UserContext";
import { useNavigate } from "react-router";

const Login = () => {
  const [loading, setLoading] = useState(false);


  const {user, setUser} = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is loggedIn
    if(user){
      navigate("/admin/batch");
    }
    
  }, [user])

  const onFinish = (values) => {
    setLoading(true);

    console.log("Received values of form: ", values);
    signInWithEmailAndPassword(auth, values?.email, values?.password)
      .then((userCredential) => {
        // Signed in
        const loggedInUser = userCredential.user;
        setUser(loggedInUser);
        navigate("/admin/dashboard");

        console.log(user);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        message.error("Invalid Credentials")
        setLoading(false);
        return;
      });
  };
  return (
    <div>
      <Flex justify="center" align="center" style={{ height: "100vh" }}>
        <Card
          title="Login"
          bordered={false}
          style={{
            width: 300,
            maxHeight: 270,
          }}
        >
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email Id!",
                },
              ]}
              style={{ marginTop: 10 }}
            >

              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email Id"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item style={{ marginTop: 10 }}>
              <Button
                htmlType="submit"
                className="login-form-button"
                style={{ width: 80 }}
              >
                {loading ? <Spin /> : "Log in"}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Flex>
    </div>
  );
};

export default Login;
