import React from 'react';
import InformationIcon from "../assets/information.png";

const MasterclassComp = ({setIsModalOpen, SpeakerImage, onClick, name }) => {
  return (
    <div className="box box-masterclass">
        <img src={InformationIcon} className="information-icon-masterclass" alt="icon" onClick={onClick}/>
        <div>
        <h5 className="master-classname">{name}</h5>
        </div>
        <div className="speaker-image-container">
        <img src={SpeakerImage} className="speaker-image" alt="Speaker" />
        </div>
    </div>
  )
}

export default MasterclassComp