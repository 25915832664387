import React, { useEffect, useState } from "react";
import Dashboard from "../Page/Dashboard";
import {
  AreaChartOutlined,
  ClockCircleOutlined,
  CoffeeOutlined,
  InboxOutlined,
  LoadingOutlined,
  MailOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Input,
  List,
  message,
  Select,
  Spin,
  Steps,
  theme,
  Timeline,
  Upload,
} from "antd";
import {
  collection,
  db,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  writeBatch,
} from "../firebase";
import Papa from "papaparse";
import { sendEmail } from "../util/EmailManager";
import { useNavigate } from "react-router-dom";

const { Dragger } = Upload;


const GenerateWIP = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [csvFile, setCSVFile] = useState(null);
  const [parsedData, setParsedData] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);

  const next = () => {
      uploadDataToDatabase();
  };
 
  const csvParserConfig = {
    delimiter: "", // auto-detect
    newline: "", // auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: true,
    transformHeader: undefined,
    dynamicTyping: false,
    preview: 0,
    encoding: "",
    worker: false,
    comments: false,
    step: undefined,
    complete: (data) => {
      updateDataToDatabase(data);
    },
    error: () => {
      message.error("Error parsing the csv file");
    },
    download: false,
    downloadRequestHeaders: undefined,
    downloadRequestBody: undefined,
    skipEmptyLines: false,
    chunk: undefined,
    chunkSize: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [",", "\t", "|", ";", Papa.RECORD_SEP, Papa.UNIT_SEP],
    skipFirstNLines: 0,
  };

  const navigate = useNavigate();

  useEffect(() => {
    setCSVFile(null);

  }, []);

  const getDate = () => {
    const currentDate = new Date();
    const dateForNextYear = currentDate.setFullYear(currentDate.getFullYear() + 1);
    const dateFormat = new Date(dateForNextYear);

    const day = dateFormat.getDate();
    const month = dateFormat.getMonth() + 1
    const year = dateFormat.getFullYear();

    return day + "/" + month + "/" + year;
  }

  const uploadDataToDatabase = async () => {
    if (!selectedBatch) {
      message.error({content: "Please select a batch", duration: 2});
      return;
    }

    // Check if email Exists
    const q = query(
      collection(db, "emails"),
      where("batch_id", "==", selectedBatch)
    );

    const querySnapshot = await getDocs(q);

    // If no document found
    if (querySnapshot.docs.length === 0) {
      message.error("No email found for this batch", );
      return;
    }

    // Check if masterclasses are made
    const masterclassQuery = query(
      collection(db, "masterclasses"),
      where("batchId", "==", selectedBatch)
    );

    const masterclassSnapshot = await getDocs(masterclassQuery);

    // If no document found
    if (masterclassSnapshot.docs.length === 0) {
      message.error("No masterclass data found for this batch");
      return;
    }

    // Check if file exists
    console.log(csvFile);
    if (!csvFile) {
      message.error("Please upload a csv file");
      return;
    }
    const parsedData = Papa.parse(csvFile?.originFileObj, csvParserConfig);
    //  console.log(parsedData);
  };

  const steps = [
    {
      title: "Upload",
      content: (
        <UploadCSV
          setCSVFile={setCSVFile}
          setSelectedBatch={setSelectedBatch}
        />
      ),
    }
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: "260px",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const updateDataToDatabase = (parsedData) => {
    setLoading(true);
    try {

    
      const dataArray = [];
      
      parsedData?.data?.forEach((data) => {
        // selectedBatch
        dataArray.push({
          uniqueLink:  data["Name"].replace(/ +/g, "").toLowerCase() + Math.floor(100000 + Math.random() * 999999),
          //TODO: WIP ID
          wipId: "WV" + Math.floor(100000 + Math.random() * 999999),
          wipValidity: getDate(),
          name: data["Name"],
          emailId: data["Email"],
          stream: data["Stream"],
          educationalInstitute: data["Educational Institute"],
          hoursOfRolePlay: data["Hours Of Roleplay"],
          problemsSolved: data["Problems Solved"],
          uniquePersonalities: data["Uniqure Personalities Tackle"],
          // For Bars
          workplaceTools: data["Workplace Tools Learnt"],
          problemSolving: data["Problem Solving"],
          smartThinking: data["Smart Thinking "],
          remoteCommunication: data["Remote Communication"],
          negotiation: data["Negotiation"],
          emotionalIntelligence: data["Emotional Intelligence"],
          storytelling: data["Storytelling"],
          collaboration: data["Collaboration"],
          productivity: data["Productivity"],
          // Interpretation
          framesLogic: data["Frames Logic"],
          connectsRelated: data["Connects related and unrelated"],
          communicates: data["Communicates With Clarity"],
          makesInformed: data["Makes Informed Assumption"],
          breakDownProblem: data["Breaks Down Problem"],
          batchId: selectedBatch,
          emailSent: "Pending",
        });
      });



      const batch = writeBatch(db);

    

      const promises = dataArray.map((data) => {
        const collectionRef = doc(collection(db, "wips"));
         batch.set(collectionRef, data); // Add the data to the batch for this document reference
      });


      Promise.all(promises)
      .then(() => {
        // All data has been written to the batch, now commit the batch
        batch.commit()
          .then(async () => {
            message.success("Process 1/2 Completed");

              // Send Emails
              await sendEmail(selectedBatch, dataArray).then((result) => {
                console.log("Success" + result);
                message.success("Process 2/2 Completed");
                setLoading(false)
                navigate("/admin/wip"); 
              }).catch((err) => {
                console.log(err)
                message.error("Error sending emails, please send manually");
                setLoading(false);
              })
          })
          .catch((error) => {
            console.log(error);
            message.error("Error generating WIPs, please do manually or try again");
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        message.error("Error adding data to batch, please try again");
        setLoading(false);
      });


    } catch (error) {
      console.log(error);
      message.error("Failed, please reload and try again")
      setLoading(false);
    }
  };

  return (
    <Dashboard>
      <>
        <Steps current={current} items={items} />
        <div style={contentStyle}>{steps[current].content}</div>
        <div
          style={{
            marginTop: 24,
          }}
        >
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => uploadDataToDatabase()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => next()}
            >
              {loading ? <Spin /> : "Done" }
            </Button>
          )}
          {/* {current > 0 && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )} */}
        </div>
      </>
    </Dashboard>
  );
};

export default GenerateWIP;

const UploadCSV = ({ setCSVFile, setSelectedBatch }) => {
  const props = {
    name: "file",
    multiple: true,
    accept: ".csv",
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      setCSVFile(info.file);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const [batches, setBatches] = useState(null);

  useEffect(() => {
    getBatches();
  }, []);

  const getBatches = async () => {
    const querySnapshot = await getDocs(collection(db, "batches"));
    const batchData = [];
    querySnapshot.forEach((doc) => {
      batchData.push({
        key: doc.id,
        id: doc.id,
        ...doc.data(),
        value: doc?.id,
        label: doc.data()?.name,
      });
    });
    console.log(batchData);
    setBatches(batchData);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          margin: "-60px auto 0 auto",
          textAlign: "center",
        }}
      >
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Search a Batch"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={batches}
          onChange={(e) => setSelectedBatch(e)}
        />
        <div style={{ width: "50%", margin: "-80px auto 30px auto" }}>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">.csv file supported only.</p>
          </Dragger>
        </div>
      </div>
    </>
  );
};
