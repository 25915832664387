import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, db, doc, getDoc, getDocs, query, where } from "../../firebase";
import WorkverseLogo from "../../assets/logo.png";
import OtherLogos from "../../assets/rect.png";
import "./userwip.css";
import InformationIcon from "../../assets/information.png"
import ProgressBar from "../../Component/ProgressBar";
import BrainImage from "../../assets/brain.png";
import QuestionMark from "../../assets/icons/svg/Frames Questions with logic.svg";
import Network from "../../assets/icons/svg/Connects the dots to draw actionable conclusions.svg";
import Chess from "../../assets/icons/svg/Communicates with clarity.svg";
import People from "../../assets/icons/svg/Takes independent decision.svg";
import Bulb from "../../assets/icons/svg/breaks down problem.svg";
import IconText from "../../Component/IconText";
import { Grid } from "@mui/material";
import { Modal, message } from 'antd';
import Loader from "../../Component/Loader";
import MasterclassComp from "../../Component/MasterclassComp";
import Expired from "../../Component/Expired";
import LinkedinImage from "../../assets/linkedin.png"
import {softskillsdata} from "../../softskilldata"



const Userwip = () => {
  const { wipID } = useParams();
  const [wipData, setWipData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [informationContent, setInformationContent] = useState(null);
  const [masterClassData, setMasterClassData] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);
  const [batchData, setBatchData] =useState(null);
  const [dates, setDates] = useState({
    issueYear: "",
    issueMonth: "",
    expireMonth: "",
    expireYear: "" 
  })

  const slidersRef = useRef()

  const handleCancel = () => {
    setInformationContent(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    getWIPData();
  },[]);

  const getWIPData = async () => {

    if(wipID.length === 0 || wipID === null || wipID === undefined){
      window.alert("Link Invalid");
      return;
    }

    // Get users details
    const wipRef = collection(db, "wips");
    const q = query(wipRef, where("uniqueLink", "==", wipID));
    const querySnapshot = await getDocs(q);


    let batchIdTemp = "";

    if(querySnapshot.empty){
      setNotFound(true);
    }

    querySnapshot.forEach((doc) => {
  
        console.log(doc.data())

        setWipData({
          batchId: doc.data()?.batchId,
          breakDownProblem: doc.data()?.breakDownProblem,
          collaboration: doc.data()?.collaboration,
          communicates: doc.data()?.communicates,
          connectsRelated: doc.data()?.connectsRelated,
          educationalInstitute: doc.data()?.educationalInstitute,
          emailId: doc.data()?.emailId,
          emailSent: doc.data()?.emailSent,
          emotionalIntelligence: doc.data()?.emotionalIntelligence,
          framesLogic: doc.data()?.framesLogic,
          hoursOfRolePlay: doc.data()?.hoursOfRolePlay,
          makesInformed: doc.data()?.makesInformed,
          negotiation: doc.data()?.negotiation,
          problemSolving: doc.data()?.problemSolving,
          problemsSolved: doc.data()?.problemsSolved,
          productivity: doc.data()?.productivity,
          remoteCommunication: doc.data()?.remoteCommunication,
          smartThinking: doc.data()?.smartThinking,
          storytelling: doc.data()?.storytelling,
          stream: doc.data()?.stream,
          uniqueLink: doc.data()?.uniqueLink,
          uniquePersonalities: doc.data()?.uniquePersonalities,
          workplaceTools: doc.data()?.workplaceTools,
          name: doc.data()?.name,
          wipValidity: doc.data()?.wipValidity,
          wipId: doc.data()?.wipId
        })

        batchIdTemp = doc.data()?.batchId; 
      
        getMasterClassData(batchIdTemp);
        getBatchData(batchIdTemp);
    });

  };

  useEffect(() => {
    

},[])

const getBatchData = async (batchId) => {
    if(!batchId) return;
    // Get users details
    const docRef = doc(db, "batches", batchId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      setBatchData(docSnap.data())
    } else {
      // docSnap.data() will be undefined in this case
      message.error("Some Error Occured");
    }
    
}

const handleExpiry = () => {
  const createdDateRaw = wipData?.wipValidity?.split("/")
  // const createdDateRaw = validityDate.split("/")
        
  if(createdDateRaw){
    const day = createdDateRaw[0];
    const month = createdDateRaw[1];
    const year = createdDateRaw[2];
    const generatedDate = new Date(year, month - 1, day);
    const currentDate = new Date();

    setDates({
      expireMonth: month,
      expireYear: year,
      issueMonth: month,
      issueYear: (year - 1) 
    })

    if(generatedDate < currentDate) {
      setExpired(true);
    }else{  
      setExpired(false);
    }
  }
}

  const getMasterClassData = async (batchId) => {
   console.log(batchId);
    if(!batchId) return;
    // Get users details
    const masterclassRef = collection(db, "masterclasses");
    const q = query(masterclassRef, where("batchId", "==", batchId));
    const querySnapshot = await getDocs(q);

    const masterClassData = [];

    querySnapshot.forEach((doc) => {
        console.log(doc.data());
        masterClassData.push({
          id: doc.id,
          ...doc.data(),
        })

    });

    setMasterClassData(masterClassData);

    // Get master class detaiks   
  };

  const calculateTotalSuperPowerActive = () => {
    let counter = 0;
    if(wipData?.framesLogic === "TRUE"){
      counter++;
    }

    if(wipData?.makesInformed === "TRUE"){
      counter++;
    }

    if(wipData?.connectsRelated === "TRUE"){
      counter++;
    }

    if(wipData?.communicates === "TRUE"){
      counter++;
    }

    if(wipData?.breakDownProblem === "TRUE"){
      counter++;
    }

    return counter;

  }

  const handleDescriptor = (content) => {
    setInformationContent(content)
    setIsModalOpen(true)
  }

  return (
    <>
    <Modal 
      open={isModalOpen} 
      okButtonProps={{style: { display: "none" }}} 
      cancelButtonProps={{style: {display: "none"}}} 
      onCancel={handleCancel}
      className="information-box-modal"
    >

      <p style={{marginTop: 20}}>{informationContent}</p>
    </Modal>

    {wipData ?
    <>

    <Expired expired={expired}  handleExpiry={handleExpiry} />
      <div className="wip-container" >
      <div className="background-design"></div>
   
    <div className="wip-content">
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        paddingTop: expired ? "100px" : "20px",
        paddingBottom: "70px",
      }}
      className="logos-container"
    >
      {batchData?.logo_url && 
        <div className="mobile-logos">
          <img src={batchData?.logo_url} alt="workverse logo" className="workverse-logo workverse-logo-mobile"/>
        </div>
        }
     
      <div className="mobile-logos">
        <img src={WorkverseLogo} alt="workverse logo" className="workverse-logo  workverse-logo-mobile"/>
      </div>
      
    </div>
    <h1 className="wip-title">Workplace Intelligence <br className="line-break" /> Profile</h1>
    <div className="wip-meta wip-meta-mobile">
          <h4>
            <span>WIP ID:</span> {wipData?.wipId}
          </h4>
          <h4>
            <span>WIP Validity:</span> {expired ? <span className="red">Expired</span> : wipData?.wipValidity} 
          </h4>
    </div>
    <div className="box basic-info">
      <div className="basic-info-name-meta">
        <h2 className="username">{wipData?.name}</h2>

        <div className="wip-meta wip-meta-desktop">
          <h4>
            <span>WIP ID:</span> {wipData?.wipId}
          </h4>
          <h4>
            <span>WIP Validity:</span> {expired ? <span className="red">Expired</span> : wipData?.wipValidity} 
          </h4>
        </div>
      </div>

      <div className="wip-meta-education">
        <h4>
          <span>Stream:</span> {wipData?.stream}
        </h4>
        <h4>
          <span>Educational Institution: </span> {wipData?.educationalInstitute}
        </h4>
      </div>
    </div>

    <h1 className="wip-subtitle about-subtitle">About the Workplace Intelligence Profile</h1>
    <div className="box ">
      <p className= "wip-about">
      The Workplace Intelligence Profile (WIP) provides an indication of a subject’s ability to apply soft skills in a diverse range of workplace situations, and the traits they are likely to display as a professional. The WIP also certifies the training they have received in ‘The Smart Fellowship - Soft Skills for Success’ program which puts subjects in the center of 100+ workplace scenarios in a simulated environment - including solving complex problems, collaborating with unique personalities and stakeholders, and thinking critically.
      </p>

      <h5 className="wip-about-subtitle">Training Stats in a Simulated Workplace Environment</h5>
      {/* small boxes */}
        <div className="about-traits">
            <div className="box box-about">
                <h1>{wipData?.hoursOfRolePlay}</h1>
                <h4>Hours of Roleplay<br /> Undertaken</h4>
            </div>
            <div className="box box-about">
            <h1>{wipData?.problemsSolved}</h1>
                <h4>Workplace Problems <br />Solved</h4>
            </div>
            <div className="box box-about">
            <h1>{wipData?.uniquePersonalities}</h1>
                <h4>Team Projects <br />Completed</h4>
            </div>
            <div className="box box-about">
                <h1>{wipData?.workplaceTools}</h1>
                <h4>Workplace Tools Learnt</h4>
            </div>
        </div>
    </div>
    <div className="workplace-superpower-title">
      <h1 className="wip-subtitle">Soft Skills Proficiency</h1>
      {/* <div style={{zIndex: 10}}>
        <img src={InformationIcon} className="icon-heading" alt="information icon" onClick={() => setIsModalOpen(true)}/>
      </div> */}
    </div>
    <div className="box softskills-box">
      <div className="brain-container">
        <img src={BrainImage} alt="Brain" width={"80%"} className="brain-image"/>
      </div>
      <div className="soft-skills-progress-container">
        <div className="softskill-inner-progress-container" ref={slidersRef}>
          <ProgressBar detail={softskillsdata.problemSolving} title={"Problem Solving"} progress={wipData?.problemSolving} result={"Good"} handleDescriptor={handleDescriptor}/>
          <ProgressBar detail={softskillsdata.smartThinking} title={"Smart Thinking"} progress={wipData?.smartThinking} result={"Adequate"} handleDescriptor={handleDescriptor}/>
          <ProgressBar detail={softskillsdata.remoteCommunication} title={"Remote Communication"} progress={wipData?.remoteCommunication} result={"Excellent"} handleDescriptor={handleDescriptor}/>
          <ProgressBar detail={softskillsdata.negotiation} title={"Negotiation"} progress={wipData?.negotiation} result={"Adequate"} handleDescriptor={handleDescriptor}/>
        </div>
        <div className="softskill-inner-progress-container">
          <ProgressBar detail={softskillsdata.emotionalIntelligence} title={"Emotional Intelligence"} progress={wipData?.emotionalIntelligence} result={"Excellent"} handleDescriptor={handleDescriptor}/>
          <ProgressBar detail={softskillsdata.storytelling} title={"Storytelling"} progress={wipData?.storytelling} result={"Good"} handleDescriptor={handleDescriptor}/>
          <ProgressBar detail={softskillsdata.collaboration} title={"Collaboration"} progress={wipData?.collaboration} result={"Adequate"} handleDescriptor={handleDescriptor}/>
          <ProgressBar detail={softskillsdata.productivity} title={"Productivity"} progress={wipData?.productivity} result={"Excellent"} handleDescriptor={handleDescriptor}/>
        </div>
      </div>
    </div>
     

    <div className="workplace-superpower-title">
      <h1 className="wip-subtitle">Workplace Superpowers</h1>
      <h1 className="wip-subtitle">{calculateTotalSuperPowerActive()}/5</h1>
    </div>
    <div className="box workplace-sup-box">
      <IconText icon={QuestionMark} text={"Frames questions with logic and foresight"} isActive={wipData?.framesLogic === "TRUE" ? true : false}/>
      <IconText icon={Network} text={"Connects the dots to draw actionable conclusions"} isActive={wipData?.connectsRelated === "TRUE" ? true : false}/>
      <IconText icon={People} text={"Communicates with clarity, context and creativity"} isActive={wipData?.communicates === "TRUE" ? true : false}/>
      <IconText icon={Chess} text={"Takes independent decisions and makes informed assumptions"} isActive={wipData?.makesInformed === "TRUE" ? true : false}/>
      <IconText icon={Bulb} text={"Breaks down problems to their fundamental elements"} isActive={wipData?.breakDownProblem === "TRUE" ? true : false}/>
    </div>

    <div className="workplace-superpower-title">
        <h1 className="wip-subtitle">Masterclasses Attended</h1>
        {/* <h1 className="wip-subtitle">2/5</h1> */}
    </div>
    {/* <div className="box"> */}
      <Grid container spacing={2}>
        {masterClassData?.map((data, index) => {
          return  <Grid item xs={12} md={6}>
          <MasterclassComp SpeakerImage={data?.image} name={data?.name} setIsModalOpen={setIsModalOpen} onClick={() => {
           setInformationContent(data?.details);
           setIsModalOpen(true);
          }} />
       </Grid>
        })}
       
      </Grid>
    {/* </div> */}

        <div style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "80px"
        }}
        className="mobile-footer"
        >
        <h6 className="visit-workverse" style={{zIndex: 9999999, position: "relative"}}><a href="https://workverse.in" rel="noreferrer" target="_blank">www.workverse.in</a> to hire more Smart Fellows</h6>
        <h6 className="visit-workverse" style={{zIndex: 9999999, position: "relative"}}><a href={
          `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Workplace%20Intelligence%20Profile&organizationId=14376030&issueYear=${dates?.issueYear}&issueMonth=${dates?.issueMonth}&expirationYear=${dates?.expireYear}&expirationMonth=${dates?.expireMonth}&certUrl=https://wip.workverse.in/${wipID}&certId=${wipData?.wipId}`
          } rel="noreferrer" target="_blank">Add certificate to 
        <img src={LinkedinImage} width={28} alt="linkeding" style={{marginLeft: 10}}/>
        </a></h6>
        </div>
    </div>   
        </div>
        </>
        :
        <div style={{
          background: "linear-gradient(208deg, #031715 -5.05%, #052624 42.26%, #000 126.59%)",
          height: "100vh"
        }}>
          {notFound ?
            <div style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <h4 style={{color: "#fff"}}>WIP Not Found</h4>
            </div>
          :
          <Loader />
        }
        
        </div>
     
    }
     
    </>
   
  );
};

export default Userwip;
