import React from "react";

const IconText = ({ icon, isActive, text }) => {
  return (
    <div className={`icon-text-container ${!isActive && 'icon-inactive'}`} >
      <div className="logo-container">
        {/* Icon */}
        <img src={icon} alt="icon" className="texticon-icon"/>
      </div>
      {/* Text */}
      <h4 className="icon-text">{text}</h4>
    </div>
  );
};

export default IconText;
