export const softskillsdata = {
    problemSolving: {
        excellent: "For a problem solver in this range, defining the problem, its conditions, and desired objective is routine. They regularly excel in building scenarios and thoroughly assessing various outcomes to pinpoint the optimal solution. These candidates adeptly identify or create resources and imagine new possibilities of solving a problem with a high level of consistency and finesse.",
        good: "A problem solver in this range consistently exhibits the capacity to define problems, their conditions, and objectives. They frequently engage in constructing scenarios and exploring multiple outcomes to select the best solution. Their skill set includes the ability to identify or create resources and imagine new possibilities of problem solving with a commendable regularity.",
        adequate: "In the realm of problem-solving, candidates decently demonstrate the ability to define the problem, its conditions, and the desired objective. They sometimes dabble in building scenarios and contemplating multiple outcomes to choose a solution. Their identification or creation of suitable resources to help in solving a problem may not always be immediate, but improves with practice."
    },
    smartThinking: {
        excellent: "Candidates in this range for smart thinking excel in applying first principles thinking to deconstruct complex concepts into fundamental truths with remarkable precision. They expertly identify their circle of competence and harness their strengths to the fullest extent, making them invaluable problem solvers. Their decision-making process is marked by a meticulous consideration of both short-term and long-term consequences, and they consistently anticipate and skillfully avoid undesirable outcomes. Their ability to work backward is a cornerstone of their exceptional problem-solving capabilities, providing unique insights and solutions.",
        good: "Candidates in this range of smart thinking exhibit a commendable grasp of first principles thinking and regularly employ it to break down complex concepts. They consistently identify their circle of competence and effectively leverage their strengths. When making decisions, they conscientiously weigh both short-term and long-term consequences. They often consider the outcomes to avoid and skillfully work backward, making their problem-solving approach a noticeable asset in the workplace.",
        adequate: "In this range for smart thinking, the candidate demonstrates a foundational understanding of first principles thinking to break down concepts. They possess the ability to recognize their circle of competence and apply their strengths occasionally. While decision-making, they consider both short-term and long-term consequences with some room for improvement. At times, they think about the outcomes they would like to avoid and work backward to avoid potential pitfalls."
    },
    remoteCommunication: {
        excellent: "A candidate in this range of communication consistently achieves their end objective of communication. They regularly exhibit a profound understanding of the recipient's point of view and needs, expertly selecting the most fitting tone and medium. Their follow-up etiquettes, in terms of asking follow-up questions, sending reminders, or updates, are executed with a high level of precision and effectiveness.",
        good: "Candidates in this range of communication frequently ensure their end objective of communication. They often show an understanding of the recipient's point of view and needs, selecting an appropriate tone and medium. Their follow-up etiquettes,in terms of asking follow-up questions, sending reminders, or updates, demonstrate a commendable level of consistency.",
        adequate: "Candidates in this range of communication recognize the end objective of communication that they have to achieve and can often ensure it. They understand the need to consider the recipient's point of view and needs, choosing an appropriate tone and medium in many routine occasions. Their follow-up etiquettes, in terms of asking follow-up questions, sending reminders, or updates, could benefit from more consistency."
    },
    negotiation: {
        excellent: "An excellent negotiator excels in setting the right value and proposition, showcasing a deep understanding of negotiation techniques. They are prepared to make sacrifices and are confident in walking away when required. They exhibit a high degree of adaptability, readily identifying alternatives when needed. Their negotiations are well-prepared, as they thoroughly understand the other party, making them proficient in negotiations.",
        good: "A good negotiator consistently sets the right value and proposition, demonstrating a solid grasp of negotiation fundamentals. They often make sacrifices when needed and are willing to walk away if the situation demands. They display a moderate level of adaptability and are proactive in identifying alternatives when a deal doesn't work out. They consistently prepare for negotiations by understanding the other party.",
        adequate: "An adequate negotiator occasionally sets the right value and proposition, demonstrating a basic understanding of negotiation principles. They may be able to identify when it is required to make sacrifices or tradeoffs in a deal, or consider walking out when necessary. They make an attempt to incorporate adaptability in their approach and may display limited efforts to prepare for negotiations by thoroughly understanding the other party."
    },
    storytelling: {
        excellent: "An excellent storyteller meticulously sets communication goals for every interaction, ensuring a high level of relevance. Their deep understanding of listeners' moods and motives guarantees consistently captivating the recipient's interest. They can incorporate a highly logical flow to their messaging and they  adeptly identify and leverage impactful keywords, creating compelling and memorable communication.",
        good: "A good storyteller consistently sets communication goals before interactions, resulting in engaging and relevant communication. They demonstrate a strong understanding of listeners' moods and motives, keeping the audience captivated. Their communication flows logically and compellingly, ensuring sustained interest. They skillfully identify and leverage impactful keywords, enhancing their storytelling prowess.",
        adequate: "In the realm of storytelling, an adequate performer typically sets communication goals before interactions, trying to ensure relevance. They make attempts to understand listeners' moods and motives, even if there is room for improvement. Their communication features moments of logical flow that can sometimes capture interest. While they sometimes identify impactful keywords, there is potential for greater consistency in their usage."
    },
    emotionalIntelligence: {
        excellent: "In this range of emotional intelligence, candidates excel at anchoring their emotions, ensuring a steady focus on their goals. They consistently practice self-awareness, exhibit a deep understanding of various viewpoints, and possess a keen awareness of others' emotions. They skillfully connect with others, nurturing and maintaining exceptionally strong and harmonious relationships.",
        good: "Candidates in this range of emotional intelligence demonstrate a consistent ability to anchor their emotions while working towards their goals. They actively practice self-awareness and consistently show understanding of different perspectives and the emotional states of others. They are adept at connecting with their peers, building meaningful and productive relationships.",
        adequate: "In this range of emotional intelligence, candidates display some ability to focus on their goals to stabilize their emotions. When required, they may practice self-awareness and attempt to understand different points of view and the emotions of others. They make some efforts to connect with colleagues, building moderately strong relationships."
    },
    collaboration: {
        excellent: "For candidates in this range of collaboration, collaboration is second nature. They routinely apply all essential components, creating a cohesive, high-performing team. They excel at managing disagreements, fostering a shared vision, and aligning team members. They possess an in-depth understanding of team dynamics, efficiently managing interdependency of tasks, breaking them down into subtasks, and managing resources in workflows. Their collaborative skills are exemplary.",
        good: "Candidates in this range of collaboration exhibit a balanced approach. They consistently apply the core components of collaboration, contributing to a shared vision and effective teamwork. They are proficient in managing disagreements and aligning team members with a clear vision. Candidates have a grasp of team dynamics and can work with interdependency of tasks and resources in a workflow.",
        adequate: "Candidates in this range of collaboration can apply these essential components decently. They demonstrate basic skills in working with others and share a common vision and goals. They are aware of their strengths and weaknesses, though they might not fully understand team dynamics. Collaboration happens intermittently, and they have room for improvement in managing disagreements and aligning team members."
    },
    productivity: {
        excellent: "In this range of productivity, candidates excel in every aspect. They consistently manage their time and energy, set ambitious goals, and expertly prioritize tasks. Their exceptional ability to prepare for managing multiple tasks results in highly efficient and productive work. Employers can count on these candidates for consistently outstanding performance and task management.",
        good: "Candidates in this range of productivity exhibit a balanced approach. They consistently manage time and energy effectively, setting clear goals and prioritizing tasks. They are adept at preparing for managing multiple tasks, displaying strong organizational skills. Their productivity is reliable, contributing positively to the workflow.",
        adequate: "Candidates in this range of productivity demonstrate basic proficiency in key components. They can manage time and energy but may struggle with setting clear goals and prioritizing tasks. While preparing for managing multiple tasks at once, there is room for improvement in efficiency and organization. With development, they can contribute more effectively in the workplace."
    }
}