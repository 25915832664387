import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Dashboard from "../Dashboard";
import {
  Button,
  Flex,
  Space,
  Table,
  message,
  Modal,
  Form,
  Input,
  Upload,
  Drawer,
  Select,
  Spin,
} from "antd";
import {
  FormOutlined,
  DeleteOutlined,
  InboxOutlined,
  CopyOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  db,
  doc,
  query,
  getDocs,
  collection,
  deleteDoc,
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  setDoc,
  updateDoc,
  where,
} from "../../firebase";
import Title from "antd/es/typography/Title";
import Loader from "../../Component/Loader";
import CreateWipForm from "../../Component/CreateWipForm";
import { Navigate, useNavigate } from "react-router-dom";
import ViewWip from "../../Component/ViewWip";

const { Dragger } = Upload;

const collectionName = "batches";

const WIPManagement = () => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [batches, setBatches] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [batchUsers, setBatchUsers] = useState(null);
  const [random, setRandom] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [viewId, setViewId] = useState(null);
  const [wipMenu, setWipMenu] = useState({
    create: false,
    view: false,
    edit: false,
  });

  const [formData, setFormData] = useState({
    name: "",
    logo_url: "",
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
    logo_url: "",
    id: "",
  });

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Action Completed Successfully",
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const navigate = useNavigate();

  // Form Submit
  const handleOk = async () => {
    // setIsModalOpen(false);
    // console.log(formData);
    // If not create form
    // TODO: user drawer for form
    if (!isEdit) {
      try {
        await setDoc(doc(collection(db, collectionName)), {
          name: formData?.name,
          logo_url: formData?.logo_url,
        });
        message.success("Batch Created Successfully");
        setIsModalOpen(false);
        setFormData({
          name: "",
          logo_url: "",
        });
        form.resetFields();
        setRandom(Math.random);
      } catch (error) {
        console.log(error);
        message.error("There was some error creating batch");
      }

      setIsEdit(false);
      return;
    }

    // If edit form
    try {
      const batchFormRef = doc(db, collectionName, editFormData?.id);

      // Set the "capital" field of the city 'DC'
      await updateDoc(batchFormRef, {
        name: editFormData?.name,
        logo_url: editFormData?.logo_url,
      });

      message.success("Batch Created Successfully");
      setIsModalOpen(false);
      setEditFormData({
        name: "",
        logo_url: "",
        id: "",
      });
      setRandom(Math.random);
    } catch (error) {
      console.log(error);
      message.error("There was some error creating batch");
    }

    setIsEdit(false);
    return;
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  useEffect(() => {
    getBatches();
  }, [random, isEdit]);

  const getBatches = async () => {
    const querySnapshot = await getDocs(collection(db, "batches"));
    const batchData = [];
    querySnapshot.forEach((doc) => {
      batchData.push({
        key: doc.id,
        id: doc.id,
        ...doc.data(),
        value: doc?.id,
        label: doc.data()?.name,
      });
    });
    setBatches(batchData);
  };


  const columns = [
    { field: "wipId", headerName: "WIP ID", width: 170},
    { field: "uniqueLink", headerName: "WIP Link", width: 200 },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
    },
    {
      field: "emailSent",
      headerName: "Email Status",
      width: 150,
      editable: true,
    },
    {
      field: "stream",
      headerName: "Stream",
      width: 110,
      editable: true,
    },
    {
      field: "educationalInstitute",
      headerName: "Institute",
      width: 110,
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      // width: 160,
      renderCell: (param) => (
        <>
          <EyeOutlined style={{ margin: "0 7px 0 0" }} onClick={() => {
            setViewId(param.row.id);
            setOpenDrawer(true);
          }}/>
          <FormOutlined style={{ margin: "0 7px" }}  onClick={() => {
            navigate("/admin/edit/wip/" + param.row.id)
          }}/>
          <DeleteOutlined style={{ margin: "0 7px" }} onClick={async () => {
            
            try {
              await deleteDoc(doc(db, "wips", param.row.id))
              message.success("Deleted Successfully")
              handleSearch(selectedBatch)
            } catch (error) {
              console.log(error)
              message.error(error)
            }
           
          
          }}/>
        </>
      ),
    },
  ];


  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const handleSearch = async(e) => {
    setLoading(true);
    setSelectedBatch(e);
    
    const userWIPRef = collection(db, "wips");

    const q = query(userWIPRef, where("batchId", "==", e));
    const querySnapshot = await getDocs(q);
    
    const masterUserWIPData = [];

    querySnapshot.forEach((doc) => {
      console.log(doc.data())
      masterUserWIPData.push({
        id: doc.id,
        ...doc.data()
      })
    });

    setBatchUsers(masterUserWIPData);
    setLoading(false)
  }
  return (
    <Dashboard>
      <>
        {contextHolder}
        <Drawer
        title="Basic Drawer"
        placement={"right"}
        closable={false}
        onClose={closeDrawer}
        open={openDrawer}
        key={"right"}
        width={"50%"}
      >
        <ViewWip id={viewId}/>
      </Drawer>
        <Flex
          style={{ width: "100%", marginBottom: 10, marginTop: -10 }}
          justify="space-between"
        >
          <Title level={5}>WIP Management</Title>

          <>
          {/* Search Input */}
          <Select
           showSearch
           style={{
             width: 200,
           }}
           placeholder="Select A Batch"
           optionFilterProp="children"
           filterOption={(input, option) =>
             (option?.label ?? "").includes(input)
           }
           filterSort={(optionA, optionB) =>
             (optionA?.label ?? "")
               .toLowerCase()
               .localeCompare((optionB?.label ?? "").toLowerCase())
           }
           onSelect={
             (e, value) => {
               console.log(e)
               handleSearch(e)
               
             }}
           options={batches}
         />
          </>

          {!wipMenu.create && (
            <div>
              <Button
                onClick={() =>{
                  setWipMenu({
                    create: true,
                    edit: false,
                    view: false,
                  })
                  navigate("/admin/wip/generate")
                }
                 
                }
                style={{marginRight: 10}}
              >
                Generate
              </Button>

              <Button
                onClick={() =>
                  navigate("/admin/create/wip")
                  // setWipMenu({
                  //   create: true,
                  //   edit: false,
                  //   view: false,
                  // })
                }
              >
                Create
              </Button>
            </div>
          )}
        </Flex>
    
          {batchUsers && (
            <>
            {loading ?
            <Loader />
            :
            <DataGrid
            rows={batchUsers}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            density="compact"
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            sx={{ height: "95%" }}
          />
          }
            </>
             
            )}   
            </>         
    </Dashboard>
  );
};

export default WIPManagement;
