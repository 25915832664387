import React, { useEffect, useState } from "react";
import InformationIcon from "../assets/information.svg";
import { Tooltip } from 'antd';
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "@mui/material";




const ProgressBar = ({ title, progress, handleDescriptor, enterCount, detail}) => {

  const matches = useMediaQuery('(max-width:900px)');
  const [mainWidth, setMainWidth] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);

  const [inViewRef, inView] = useInView({
    triggerOnce: true, // Trigger the animation only once when it comes into view
    threshold: 0.1, // 10% of the element is visible
  });

  useEffect(() => {
    if (inView && !isAnimated) {
      setIsAnimated(true);
    }
  }, [inView, isAnimated]);

  const getResult = () => {
    if(progress < 60){
      return "adequate";
    }else if (progress >= 60 && progress < 85){
      return "good";
    }else if (progress >= 85) {
      return "excellent";
    }
  }


  return (
    <>
    <div className="progress-container-main" onClick={() => {handleDescriptor(detail[getResult()])}}>
    {matches ?
      <>
      <div>
        <h6 className="softskills-subheading">{title}</h6>
        <div className="progressbar-container">
          <div
            ref={inViewRef}
            className={`progressbar ${getResult()}-color-progress`}
            style={{ width: isAnimated ? `${progress}%` : "0%" }}
          ></div>
          <div className="progress-background"></div>
          <h6 className={`progress-result ${getResult()}-color`}>
            {getResult()}
          </h6>
        </div>
      </div>
      </>
      :
      <Tooltip placement="bottom" title={"Click to learn more"} > 
      <div>
        {console.log(mainWidth)}
        <h6 className="softskills-subheading">{title}</h6>
        <div className="progressbar-container">
          <div
            ref={inViewRef}
            className={`progressbar ${getResult()}-color-progress`}
            // style={{ width: inView && mainWidth + "%" }}
            style={{ 
              transition: 'width 2s',
              width: isAnimated ? `${progress}%` : "0%" 
            }}
          ></div>
          <div className="progress-background"></div>
          <h6 className={`progress-result ${getResult()}-color`}>
            {getResult()}
          </h6>
        </div>
      </div>
      </Tooltip>
    }

      {/* Button */}
      <div className="button-icon">
        <img
          src={InformationIcon}
          className="information-icon"
          alt="Information"
        />
        <h6 className="button-icon-text">Info</h6>
      </div>
    </div>
    </>
   
  );
};

export default ProgressBar;
