import React, { useEffect, useState } from 'react'

const Expired = ({expired, handleExpiry}) => {
     
  useEffect(() => {
    handleExpiry();
  },[])
  return (
    <div style={{
        height: "73px",
        width: "100%",
        color: "#fff",
        background: "#12504C",
        boxShadow: "0px 0px 12px 0px #15FF73",
        justifyContent: "center",
        alignItems: "center",
        display: expired ? "flex" : "none",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 99

    }}
    className='expired-text'
    >
        <p>The Workplace Intelligence Profile you are looking at has expired its validity of 1 year from the time that the candidate received their assessment-based scores.</p>
    </div>
  )
}

export default Expired