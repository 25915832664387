import React, { useContext, useEffect } from 'react'
import UserContext from '../Context/UserContext'
import { useNavigate } from 'react-router';
// import Header from '../Component/Header';
import { MailOutlined, BookOutlined, UsergroupAddOutlined, LogoutOutlined, PicLeftOutlined} from '@ant-design/icons';
import { Layout, Menu, theme, Typography } from 'antd';
import { auth } from '../firebase';
const { Header, Content, Sider } = Layout;

const { Title } = Typography;

const mainMenu = ["Batch Management", "Email Management", "Masterclass", "WIP Management", "Logout"]
const navLinks= ["/admin/batch", "/admin/email", "/admin/masterclass", "/admin/wip"];



const Dashboard = ({children}) => {
  
  const {user, setUser} = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is loggedIn
    if(!user) navigate("/admin/login");
  }, [user])

  const { token: { colorBgContainer }} = theme.useToken();

  const handleClick = (index) => {
    if(index === 4) return auth.signOut();
    navigate(navLinks[index]);
  }

  const items2 = [UsergroupAddOutlined, MailOutlined, BookOutlined, PicLeftOutlined,  LogoutOutlined].map((icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: mainMenu[index],
      onClick: () => handleClick(index),
    };
  });

  return (
    <div>
       <Layout style={{height: '100vh'}}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          background: "purple",
        }}
      >
        <Title level={4} style={{color: "#fff", lineHeight: '1', marginBottom: 0}}>Controlzed WIP Management</Title>
      </Header>
      <Layout>
        <Sider
          width={260}
          style={{
            background: colorBgContainer,
            paddingTop: "20px",
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{
              height: '100%',
              borderRight: 0,
            }}
            items={items2}
            
          />
        </Sider>
        <Layout
          style={{
            padding: '20px 24px 24px',
            minHeight: "100%"
          }}
        >
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: "100%",
              background: colorBgContainer,
              overflow: "scroll"
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
    </div>
  )
}

export default Dashboard