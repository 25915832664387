import Login from "./Page/Login";
import "./App.css"
import { Routes, Route } from "react-router-dom";
import NoMatch from "./Page/NoMatch";
import Dashboard from "./Page/Dashboard";
import UserContext from "./Context/UserContext";
import React, { useEffect } from "react";
import {auth, onAuthStateChanged } from "./firebase";
import BatchManagement from "./Page/BatchManagement/BatchManagement";
import EmailManagement from "./Page/EmailManagement/EmailManagement";
import WIPManagement from "./Page/WIPManagement/WIPManagement";
import GenerateWIP from "./Component/GenerateWIP";
import Userwip from "./Page/WIP/Userwip";
import MasterclassManagement from "./Page/Masterclass/MasterclassManagement";
import CreateWipForm from "./Component/CreateWipForm";
import EditWipForm from "./Component/EditWipForm";

function App() {

  const [user, setUser] = React.useState(null);
   
  useEffect(()=> {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        console.log("App" + user);
      } else {
        setUser(null); 
      }
    });
  },[user])

 
  

  return (
    <div className="app">
        <UserContext.Provider value={{user, setUser}}>
          <Routes>
              <Route path="/" element={<NoMatch />} />
              <Route path="/admin/login" element={<Login />} />
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route path="/admin/batch" element={<BatchManagement />} />
              <Route path="/admin/email" element={<EmailManagement />} />
              <Route path="/admin/wip" element={<WIPManagement />} />
              <Route path="/admin/wip/generate" element={<GenerateWIP />} />
              <Route path="/admin/masterclass" element={<MasterclassManagement />} />
              <Route path="/:wipID" element={<Userwip />} />
              <Route path="/admin/create/wip" element={<CreateWipForm />} />
              <Route path="/admin/edit/wip/:id" element={<EditWipForm />} />
              <Route path="*" element={<NoMatch />} />
          </Routes>
        </UserContext.Provider>
       
    </div>
    
  );
}

export default App;
