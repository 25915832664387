import React from 'react'
import { Flex, Typography } from 'antd';

const { Title } = Typography;

const NoMatch = () => {
  return (
    <div>
        <Flex justify='center' align="center" style={{width: "100%", height: "100vh"}} >
            <Title>Not Found</Title>
        </Flex>
    </div>
  )
}

export default NoMatch