import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard";
import {
  Button,
  Flex,
  Space,
  Table,
  message,
  Modal,
  Form,
  Input,
  Upload,
} from "antd";
import {
  FormOutlined,
  DeleteOutlined,
  InboxOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import {
  db,
  doc,
  query,
  getDocs,
  collection,
  deleteDoc,
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  setDoc,
  updateDoc,
} from "../../firebase";
import Title from "antd/es/typography/Title";
import Loader from "../../Component/Loader";

const { Dragger } = Upload;

const collectionName = "batches";

const BatchManagement = () => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [batches, setBatches] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [random, setRandom] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    logo_url: "",
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
    logo_url: "",
    id: ""
  });

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Action Completed Successfully",
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  // Form Submit
  const handleOk = async () => {
    // setIsModalOpen(false);
    // console.log(formData);
    // If not create form
    if(!isEdit){
      try {
        await setDoc(doc(collection(db, collectionName)), {
          name: formData?.name,
          logo_url: formData?.logo_url,
        });
        message.success("Batch Created Successfully");
        setIsModalOpen(false);
        setFormData({
          name: "",
          logo_url: "",
        });
        form.resetFields();
        setRandom(Math.random);
      } catch (error) {
        console.log(error);
        message.error("There was some error creating batch");
      }
  
      setIsEdit(false);
      return;
    }

    // If edit form
    try {

      const batchFormRef = doc(db, collectionName, editFormData?.id);

      // Set the "capital" field of the city 'DC'
      await updateDoc(batchFormRef, {
        name: editFormData?.name,
        logo_url: editFormData?.logo_url
      });

      message.success("Batch Created Successfully");
      setIsModalOpen(false);
      setEditFormData({
        name: "",
        logo_url: "",
        id: "",
      });
      setRandom(Math.random);
    } catch (error) {
      console.log(error);
      message.error("There was some error creating batch");
    }

    setIsEdit(false);
    return;
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  useEffect(() => {
    getBatches();
  }, [random, isEdit]);

  const getBatches = async () => {
    const querySnapshot = await getDocs(collection(db, collectionName));
    const batchData = [];
    querySnapshot.forEach((doc) => {
      batchData.push({
        key: doc.id,
        id: doc.id,
        ...doc.data(),
      });
    });
    console.log(batchData);
    setBatches(batchData);
  };

  // Delete on entry
  const handleDelete = async (record) => {
    try {
      await deleteDoc(doc(db, collectionName, record?.id));

      // Remove the deleted item from the state
      const batchCopy = batches;

      batches.map((batch, index) => {
        if (batch.id === record.id) {
          batchCopy.splice(index, 1);
        }
      });

      setBatches(batchCopy);
      setRandom(Math.random);
      success();
    } catch (error) {
      console.log(error);
      error();
    }

    setIsEdit(false);
  };

  // Edit one entry
  const handleEdit = (record) => {
    // batchName

    form.setFieldValue["batchName"] = editFormData?.name;

    setEditFormData({
      name: record?.name,
      logo_url: record?.logo_url,
      id: record?.id,
    });
    setIsEdit(true);
    showModal();
  };

  const handleCopy = (record) => {
    console.log(record);
    navigator.clipboard.writeText(record?.logo_url).then(
      () => {
        // invoked if the data is copied
        message.success("Copied successfully");
      },
      () => {
        // handle data copy error
        message.error("Copying failed");
      }
    );
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: ".png",
    customRequest(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        const storage = getStorage()
        const storageRef = ref(
          storage,
          `/company-logos/${info?.file?.name}`
        );
        uploadBytes(storageRef, info?.file, {contentType: 'image/png'})
          .then((snapshot) => {
            message.success(`${info.file.name} file uploaded successfully.`);
            getDownloadURL(snapshot.ref).then((downloadURL) => {
              if(isEdit){
                setEditFormData((prevValue) => {
                  return {
                    ...prevValue,
                    logo_url: downloadURL,
                  }
                 })
              }else {
                setFormData((prevValue) => {
                  return {
                    ...prevValue,
                    logo_url: downloadURL,
                  };
                });
              }
              
            });
          })
          .catch((err) => {
            console.log(err);
            message.error(`${info.file.name} file upload failed.`);
          });
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const onFinish = (data) => {
    console.log(data);
  };

  const handleInputChange = (e) => {
    setFormData((prevValue) => {
      return {
        ...prevValue,
        name: e.target.value,
      };
    });
  };

  const columns = [
    {
      title: "Logo",
      dataIndex: "logo_url",
      key: "logo_url",
      render: imageURl => <img alt={imageURl} src={imageURl} height="50px"/>,
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Url",
      dataIndex: "logo_url",
      key: "logo_url",
      sorter: (a, b) => a.age - b.age,
      sortOrder: sortedInfo.columnKey === "age" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Action",
      width: 150,
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => handleCopy(record)}>
            <CopyOutlined />
          </a>
          <a onClick={() => handleEdit(record)}>
            <FormOutlined />
          </a>
          <a onClick={() => handleDelete(record)}>
            <DeleteOutlined />
          </a>
        </Space>
      ),
    },
  ];
  return (
    <Dashboard>
      <>
        {contextHolder}
        <Modal
          title={isEdit ? "Edit Batch" : "Create Batch"}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {isEdit ? (
            <>
              <Input
                placeholder="Batch Name"
                style={{ marginTop: 20 }}
                onChange={(e) => {setEditFormData((prevValue) => {
                    return{
                      ...prevValue,
                      name: e.target.value
                    }
                  })
                }}
                value={editFormData?.name}
              />
              <Dragger {...props} fileList={[{name: "Batch Logo", url: editFormData?.logo_url}]} style={{marginTop: 20}}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Only .png file is supported.</p>
              </Dragger>
            </>
          ) : (
            <Form
              name="batchForm"
              form={form}
              className="batch-name"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="batchName"
                rules={[
                  {
                    required: true,
                    message: "Please enter a batch name",
                  },
                ]}
                style={{ marginTop: 10 }}
              >
                <Input
                  placeholder="Batch Name"
                  style={{ marginTop: 20 }}
                  onChange={(e) => handleInputChange(e)}
                  value={formData.name}
                />
              </Form.Item>

              <Dragger {...props} showUploadList={false}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Only .png file is supported.</p>
              </Dragger>
            </Form>
          )}
        </Modal>
        <Flex
          style={{ width: "100%", marginBottom: 10, marginTop: -10 }}
          justify="space-between"
        >
          <Title level={5}>Batch Management</Title>
          <Button onClick={showModal}>Create</Button>
        </Flex> 
        {batches ?
         <Table
         columns={columns}
         dataSource={batches}
         size="small"
         onChange={handleChange}
       />
        :
        <Loader />
      }
      </>
    </Dashboard>
  );
};

export default BatchManagement;
