import { ThunderboltOutlined } from "@ant-design/icons";
import { Input, Select, Col, Row, Button, Divider, Spin, message,Checkbox } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { collection, db, doc, getDoc, getDocs, setDoc, updateDoc } from "../firebase";
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from "../Page/Dashboard";

const style = { padding: "6px" };



const EditWipForm = () => {

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [uniqueNumber, setUniqueNumber] = useState(0);
  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [formData, setFormData] = useState({
    wipID: "WV" + Math.floor(100000 + Math.random() * 999999),
    fullName: "",
    emailId: "",
    wipValidity: "",
    stream: "",
    educationalInstitute: null,
    hoursOfRolePlay: null,
    problemsSolved: null,
    uniquePersonalities: null,
    workplaceTools: null,
    problemSolving: null,
    smartThinking: null,
    remoteCommunication: null,
    negotiation: null,
    emotionalIntelligence: null,
    storytelling: null,
    collaboration: null,
    productivity: null,
    uniqueLink: null,
    framesLogic: false,
    connectsRelated: false,
    communicates: false,
    makesInformed: false,
    breakDownProblem: false,
  })

  const navigate = useNavigate();
  const { id } = useParams();

  const framesLogicRef = useRef(null)

  useEffect(() => {
    handleGenerateUniqueLink();
    getData();
  },[])


  const handleGenerateUniqueLink = () => {
    const randomNumber = Math.floor(100000 + Math.random() * 999999);
    setUniqueNumber(randomNumber);
  }

  const getData = async() => {
    const querySnapshot = await getDocs(collection(db, "batches"));
    const batchData = [];
    querySnapshot.forEach((doc) => {
      batchData.push({
        key: doc.id,
        id: doc.id,
        ...doc.data(),
        value: doc?.id,
        label: doc.data()?.name,
      });
    });
    setBatches(batchData);

    // Get User data
    const docRef = doc(db, "wips", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const userData = docSnap.data();
        setFormData({
            wipID: userData?.wipID,
            fullName: userData?.name,
            emailId: userData?.emailId,
            wipValidity: userData?.wipValidity,
            stream: userData?.stream,
            educationalInstitute: userData?.educationalInstitute,
            hoursOfRolePlay: userData?.hoursOfRolePlay,
            problemsSolved: userData?.problemsSolved,
            uniquePersonalities: userData?.uniquePersonalities,
            workplaceTools: userData?.workplaceTools,
            problemSolving: userData?.problemSolving,
            smartThinking: userData?.smartThinking,
            remoteCommunication: userData?.remoteCommunication,
            negotiation: userData?.negotiation,
            emotionalIntelligence: userData?.emotionalIntelligence,
            storytelling: userData?.storytelling,
            collaboration: userData?.collaboration,
            productivity: userData?.productivity,
            uniqueLink: userData?.uniqueLink,
            framesLogic: userData?.framesLogic,
            connectsRelated: userData?.connectsRelated,
            communicates: userData?.communicates,
            makesInformed: userData?.makesInformed,
            breakDownProblem: userData?.breakDownProblem,
        })

        setSelectedBatch(userData?.batchId)
        setLoadingData(false);
        console.log("Document data:", docSnap.data());

    } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
    }

  }

  

  const handleSave = async () => {
    setLoading(true)
    if(!formData.emailId) {
      message.error("Please Enter Email ID");
      setLoading(false)
      return;
    }

    if(!selectedBatch){
      message.error("Please Select A Batch")
      setLoading(false)
      return;
    }

    try {

    const wipRef = doc(db, "wips", id);
      const result = await updateDoc(wipRef, {
        //TODO: WIP ID
        name: formData.fullName,
        emailId: formData.emailId,
        // TODO: GET WIP VALIDIDY
        stream: formData.stream,
        educationalInstitute: formData.educationalInstitute,
        hoursOfRolePlay: formData.hoursOfRolePlay,
        problemsSolved: formData.problemsSolved,
        uniquePersonalities: formData.uniquePersonalities,
        // For Bars
        workplaceTools: formData.workplaceTools,
        problemSolving: formData.problemSolving,
        smartThinking: formData.smartThinking,
        remoteCommunication: formData.remoteCommunication,
        negotiation: formData.negotiation,
        emotionalIntelligence: formData.emotionalIntelligence,
        storytelling: formData.storytelling,
        collaboration: formData.collaboration,
        productivity: formData.productivity,
        // Interpretation
        framesLogic: formData.framesLogic,
        connectsRelated: formData.connectsRelated,
        communicates: formData.communicates,
        makesInformed: formData.makesInformed,
        breakDownProblem: formData.breakDownProblem,
      })        
        console.log("success")
        navigate("/admin/wip");
        message.success("Successfully Generated WIP");
        setLoading(false)
      
    } catch (error) {
      message.error(error)
      
    }
    console.log("Here")
    navigate("/admin/wip");
    console.log("Then")

  }

  const handleCheckBox = (e) => {
    console.log(e.target);
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [e.target.value]: e.target.checked === true ? "TRUE" : "FALSE" ,
      }
    })

  }

  const handleChange = (e) => {
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      }
    })
    console.log(formData);
  } 

  return (
    <Dashboard >
      <>
      {loadingData ?
      <div  style={{display: "flex",width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <Spin size="large"/>
     </div>
     :
     <div>
     <Row gutter={16}>
       <h6 style={{marginBottom: "-20px", marginLeft: "8px", marginTop: "10px"}}>Basic Details</h6>
       <Divider />
       <Col className="gutter-row" style={style} span={12}>
         <Input placeholder="Unique Link" value={formData.uniqueLink} disabled/>
       </Col>
       <Col className="gutter-row" style={style} span={12}>
         <Input value={formData.wipID} placeholder="WIP Id" disabled/>
       </Col>
       <Col className="gutter-row" style={style} span={12}>
       <Input onChange={handleChange} name="emailId" value={formData.emailId} placeholder="Email Id" type="email" required />
       </Col>
       <Col className="gutter-row" style={style} span={12}>
         <Input  placeholder="Full Name" name="fullName" value={formData.fullName} onChange={(e) => {
           setFormData((prevVale) => {
             return {
               ...prevVale,
               fullName: e.target.value,
               uniqueLink: (e.target.value + uniqueNumber).replace(/ +/g, "").toLowerCase()
             }
           })

         }} />
       </Col>
       <Col className="gutter-row" style={style} span={12}>
         <Input onChange={handleChange} name="wipValidity"  value={formData.wipValidity} placeholder="WIP Validity : dd/mm/yyyy" />
       </Col>
       <Col className="gutter-row" style={style} span={12}>
         <Input  onChange={() => {
           framesLogicRef.current.checked = true
         }} name="stream" value={formData.stream} placeholder="Stream" />
       </Col>
       <Col className="gutter-row" style={style} span={12}>
         <Input onChange={handleChange} name="educationalInstitute" value={formData.educationalInstitute} placeholder="Educational Institutions" />
       </Col>
       <Col className="gutter-row" style={style} span={12}>
        
       </Col>

       <h6 style={{marginBottom: "-20px", marginLeft: "8px", marginTop: "20px"}}>Training Stats</h6>
       <Divider />

       <Col className="gutter-row" style={style} span={6}>
         <Input onChange={handleChange} name="hoursOfRolePlay" value={formData.hoursOfRolePlay} placeholder="Hours of roleplay undertaken" type="number" />
       </Col>
       <Col className="gutter-row" style={style} span={6}>
         <Input onChange={handleChange} name="problemsSolved" value={formData.problemsSolved} placeholder="Problems Solved" type="number" />
       </Col>
       <Col className="gutter-row" style={style} span={6}>
         <Input onChange={handleChange} name="uniquePersonalities" value={formData.uniquePersonalities} placeholder="Unique Personalities Tackle" type="number" />
       </Col>
       <Col className="gutter-row" style={style} span={6}>
         <Input  onChange={handleChange} name="workplaceTools" value={formData.workplaceTools} placeholder="Workplace Tool Learnt" type="number" />
       </Col>

       <h6 style={{marginBottom: "-20px", marginLeft: "8px", marginTop: "20px"}}>Soft Skills Proficiency</h6>
       <Divider />

       <Col className="gutter-row" style={style} span={6}>
         <Input  onChange={handleChange} name="problemSolving" value={formData.problemSolving} placeholder="Problem Solving" type="number" />
       </Col>
       <Col className="gutter-row" style={style} span={6}>
         <Input onChange={handleChange} name="smartThinking" value={formData.smartThinking} placeholder="Smart Thinking" type="number" />
       </Col>
       <Col className="gutter-row" style={style} span={6}>
         <Input onChange={handleChange} name="remoteCommunication" value={formData.remoteCommunication} placeholder="Remote Communication" type="number" />
       </Col>
       <Col className="gutter-row" style={style} span={6}>
         <Input onChange={handleChange} name="negotiation" value={formData.negotiation} placeholder="Negotiation" type="number" />
       </Col>


       <Col className="gutter-row" style={style} span={6}>
         <Input onChange={handleChange} name="emotionalIntelligence" value={formData.emotionalIntelligence} placeholder="Emotional Intelligence" type="number" />
       </Col>
       <Col className="gutter-row" style={style} span={6}>
         <Input onChange={handleChange} name="storytelling" value={formData.storytelling} placeholder="Storytelling" type="number" />
       </Col>
       <Col className="gutter-row" style={style} span={6}>
         <Input onChange={handleChange} name="collaboration" value={formData.collaboration} placeholder="Collaboration" type="number" />
       </Col>
       <Col className="gutter-row" style={style} span={6}>
         <Input onChange={handleChange} value={formData.productivity} name="productivity" placeholder="Productivity" type="number" />
       </Col>

       <Checkbox.Group
         style={{
           width: '100%',
           margin: "20px 0 20px 7px"
         }}
       >
         <Row>
           <Col span={24}>
             <input type="checkbox" onChange={handleCheckBox} value="framesLogic" ref={framesLogicRef} defaultChecked={formData?.framesLogic === "TRUE" ? true : false } style={{marginRight: 4}}/>Frames questions with logic and foresight
           </Col>
           <Col span={24}>
             <input type="checkbox" onChange={handleCheckBox} value="connectsRelated" defaultChecked={formData?.connectsRelated === "TRUE" ? true : false} style={{marginRight: 4}}/>Connects the dots to draw actionable conclusions
           </Col>
           <Col span={24}>
             <input type="checkbox"  onChange={handleCheckBox} value="communicates" defaultChecked={formData?.communicates === "TRUE" ? true : false} style={{marginRight: 4}}/>Communicates with clarity, context and creativity
           </Col>
           <Col span={24}>
             <input type="checkbox"  onChange={handleCheckBox} value="makesInformed" defaultChecked={formData?.makesInformed === "TRUE" ? true : false} style={{marginRight: 4}}/>Takes independent decisions and makes informed assumptions
           </Col>
           <Col span={24}>
             <input type="checkbox" onChange={handleCheckBox} value="breakDownProblem" defaultChecked={formData.breakDownProblem === "TRUE" ? true : false} style={{marginRight: 4}}/>Breaks down problems to their fundamental elements
           </Col>
         </Row>
       </Checkbox.Group>

        <Col className="gutter-row" style={style} span={12}>
         <Select
           showSearch
           style={{
             width: 200,
           }}
           placeholder="Select A Batch"
           optionFilterProp="children"
           filterOption={(input, option) =>
             (option?.label ?? "").includes(input)
           }
           filterSort={(optionA, optionB) =>
             (optionA?.label ?? "")
               .toLowerCase()
               .localeCompare((optionB?.label ?? "").toLowerCase())
           }
           onSelect={
             (e, value) => {
               console.log(e)
               setSelectedBatch(e);
             }}
           options={batches}
           value={selectedBatch}
         />
       </Col>



       <Col className="gutter-row" style={style} span={24}>
         {/* TODO: Add Loader */}
         <Button style={{width: "150px", marginTop: "10px"}} onClick={() => handleSave()}>
           {loading ? <Spin /> : "Update"}
         </Button>
       </Col>
       <p style={{fontStyle: "italic", opacity: "0.7"}}>Note: Masterclass Data will be taken from the batch</p>

     </Row>
   </div>
     }
     
      </>
    
    </Dashboard>
  );
};

export default EditWipForm;
