// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, query, where, getDoc, getDocs, doc, deleteDoc, setDoc, updateDoc, writeBatch } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCkHipZRA2cIl7cSvGVR2NkorjMNdLMrlw",
  authDomain: "wip-host.firebaseapp.com",
  projectId: "wip-host",
  storageBucket: "wip-host.appspot.com",
  messagingSenderId: "859044371419",
  appId: "1:859044371419:web:51ea467660d43550b265fe",
  measurementId: "G-504T7EXHZT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
export {
  app,
  analytics,
  auth,
  db,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  collection,
  getDoc,
  getDocs,
  where,
  query,
  doc,
  deleteDoc,
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  setDoc,
  updateDoc,
  writeBatch
}

